<template>
    <section class="content">
        <div class="card">
            <div class="card-body" style="align-self: center; display: flex;">
                <span style="align-self: center; font-size: initial;">Tanggal</span>&nbsp;&nbsp;
                <div class="input-group">
                  <datepicker v-model="form.transaksi_tanggal" :options="{format:'dd/mm/yyyy', startView: 'days', minViewMode: 'days', autoclose: true}" :change="onChange()"/>
                  <div class="input-group-append">
                    <span class="input-group-text"><i class="fas fa-calendar"></i></span>
                  </div>
                </div>
            </div>
        </div>
        <div class="table-responsivex">
            <table class="table table-hover" ref="tableuser" style="width: 100%;">
                <thead>
                    <tr>
                        <th>No Jurnal</th>
                        <th>Tanggal</th>
                        <th>Transaksi</th>
                        <th>Masuk</th>
                        <th>Keluar</th>
                        <th>Saldo</th>
                        <th>Cabang</th>
                        <th>Note</th>
                        <th>Evidence</th>
                    </tr>
                </thead>
                <tbody @click="handleClick">
                </tbody>
            </table>
        </div>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ formTitle }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="col-md-7 form-group">
                                <label class="control-label">Branch</label>
                                <v-select :options="optCabang" v-model="form.branch_id" :reduce="opt => opt.id" />
                            </div>
                            <div class="col-md-7 form-group">
                                <label class="control-label">No Jurnal</label>
                                <input type="hidden" name="row_id" value="" v-model="form.id"/>
                                <input id="email" class="form-control email" v-model="form.no_jurnal" type="text=" required="required" name="email" />
                            </div>
                            <div class="col-md-7 form-group">
                                <label class="control-label">Tanggal</label>
                                <datepicker v-model="form.tanggal" :options="{format:'dd/mm/yyyy', startView: 'days', minViewMode: 'days'}" :change="onChange()"/>
                            </div>
                            <div class="col-md-7 form-group">
                                <label class="control-label">Transaksi</label>
                                <input id="email" class="form-control email" v-model="form.transaksi" type="text=" required="required" name="email" />
                            </div>
                            <div class="col-md-7 form-group">
                                <label class="control-label">Note</label>
                                <textarea class="form-control" v-model="form.keterangan"></textarea>
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="inputFile">Evidence</label>
                                    <input type="hidden" name="img_code" value="" v-model="form.filecode" id="img_code" />
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input inputfile" id="inputFile"  name="file" @change="uploadFoto(this)">
                                    <label class="custom-file-label" for="inputFile">Choose file</label>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="tbl">
                                    <button type="button" class="btn btn-primary add" v-on:click="addItem" style="margin-bottom: 12px;">Tambah item</button>
                                    <table class="table table-hover" ref="tableitem" id="tblform" style="width: 100%;">
                                        <thead id="tblform2">
                                            <tr>
                                                <th width="30%">No Akun</th>
                                                <th width="20%">Masuk</th>
                                                <th width="20%">Keluar</th>
                                                <th>Action</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody id="tblform3">
                                        </tbody>
                                      </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import moment from 'moment';
import datepicker from '@/components/Datepicker';

export default {
    name: 'DataGuru',
    components: {
        vSelect,
        datepicker,
    },
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            formTitle: 'Tambah',
            optCabang: [],
            optBarang: [],
            optCoa: [],
            optType: [
                {id: '0', label: 'Barang Masuk'},
                {id: '1', label: 'Barang Keluar'},
                {id: '2', label: 'Stock Opname'}
            ],
            form: {
                barang_id: '',
                harga_beli: '',
                transaksi_tanggal: '',
                transaksi_tipe: '',
                qty: '',
                branch_id: '',
                keterangan: '',
            },
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)

        authFetch('/management/inventaris/cabang')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optCabang = js.data;
            })

        authFetch('/management/inventaris/barang')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optBarang = js.data;
            })
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
            // if (e.srcElement.getAttribute('class') == 'btn btn-primary') {
            //     this.$router.push('/inventaris-detail/'+e.srcElement.getAttribute('data-id'));
            // }
            if (e.srcElement.getAttribute('class') == 'btn btn-primary') {
                this.download(e.srcElement.getAttribute('data-href'));
            }
        },
        addItem: function(ev) {
            // var nRow = this.tbl.fnAddRow(this.formItem);
            var aData = {
              coa_hierarchy: '',
              coa_name: '',
              masuk: '',
              keluar: '',
              coa_id: '',
            };
            var nRow = this.tbl.fnAddRow('<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Delete</a></div>');
            // this.tbl.fnUpdate(aData, nRow);
            $('td .btn.edit', nRow).click();
            ev.preventDefault();
        },
        onChange: function() {
            var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
            namahari = namahari.split(" ");
            if (this.form.tanggal) {
                var split = this.form.tanggal.split('/')
                var tgl = new Date(split[2],split[1]-1,split[0]);
                var hari = tgl.getDay();

                this.form.hari = namahari[hari];
            }
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var self = this;
            var items = [];
            this.errors = [];

            var file_data = $('#inputFile').prop('files');

            this.tbl
                .api()
                .rows()
                .every(function (rowIdx, tableLoop, rowLoop) {
                  var d = this.data();
                  items.push(d);
                });

            let dataItem = '';
            items.map(function(val, key){
                Object.keys(val)
                .map(
                  (key) =>
                    dataItem += encodeURIComponent(key) + "[]=" + encodeURIComponent(val[key]) + '&'
                );
              })
            var urlSubmit = '/keuangan/jurnal_umum';
            if (this.method == 'PUT') urlSubmit = '/keuangan/jurnal_umum/' + this.form.id;

            if (file_data.length != 0) {
                this.doUpload().then((res) => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}

                    return res.json();
                    
                }).then(js => {
                    if (!js.status) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    } else {
                        if (js.message[0]) {
                            self.form.filecode = js.message[0].code;
                            console.log(js.message[0])
                            console.log(self.form)
                        }

                        var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
                        authFetch(urlSubmit, {
                                method: this.method,
                                body: data+'&'+dataItem
                            })
                            .then(res => {
                                if (res.status === 201) {

                                } else if (res.status === 400) {}
                                return res.json();
                            })
                            .then(js => {

                                this.errors = [];
                                if (!js.success) {
                                    console.log(js.details)

                                    for (var key in js.details) {
                                        if (js.details.hasOwnProperty(key)) {
                                            this.errors.push(js.details[key])
                                        }
                                    }

                                    return;
                                }
                                this.table.api().ajax.reload();
                                $(e.formDialog).modal('hide');
                            });
                    }
                })
            } else {
                var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
                authFetch(urlSubmit, {
                        method: this.method,
                        body: data+'&'+dataItem
                    })
                    .then(res => {
                        if (res.status === 201) {

                        } else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {

                        this.errors = [];
                        if (!js.success) {
                            console.log(js.details)

                            for (var key in js.details) {
                                if (js.details.hasOwnProperty(key)) {
                                    this.errors.push(js.details[key])
                                }
                            }

                            return;
                        }
                        this.table.api().ajax.reload();
                        $(e.formDialog).modal('hide');
                    });
            }

            ev.preventDefault();
        },
        changeLabel: function(event) {
            console.log(event.target.value)
            if (event.target.value == '2') {
                $('.qty').html('Qty Aktual');
            } else {
                $('.qty').html('Qty');
            }
        },
        loadCoa: async function() {
            let vm = this;
            const res = await authFetch('/keuangan/jurnal_umum/coa');
            vm.optCoa = await res.json();
        },
        uploadFoto : function (file) {
            var file_data = $('#inputFile').prop('files');
            var form_data = new FormData();
            var fileInput = $('#inputFile')[0];
            $.each(fileInput.files, function(k,file){
              form_data.append('file', file);
            });
            form_data.append('subdir', '/jurnal-umum/');
            form_data.append('id', this.form.id);
            this.form_data = form_data
            return false
        },
        doUpload: function() {
            return authFetch('/management/cabang/upload_data', {
                method: 'POST',
                body: this.form_data,
                headers: {
                'Content-Type': null,
                }
            })
        },
        download: function(data) {
          window.open(window.$apiUrl+data, "_blank")
        }
    },
    async mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tableuser, {
            "title": "List",
            "roles": this.$route.params.roles,
            "ajax": "/keuangan/jurnal_umum",
            scrollX: true,
            "columns": [
                { "data": "no_jurnal" },
                { "data": "tanggal" },
                { "data": "transaksi" },
                { "data": "masuk" },
                { "data": "keluar" },
                { "data": "saldo" },
                { "data": "branch" },
                { "data": "keterangan" },
                { "data": "filecode" },
            ],
            filterBy: [0],
            "rowCallback": function(row, data) {
                // $('td:eq(5)', row).html('<a type="button" class="btn btn-primary" data-id="'+data.id+'">Detail</a>');
                if(data.filecode)$('td:eq(8)', row).html('<a type="button" class="btn btn-primary" data-href="/file/download/'+data.filecode+'">Download</a>');
            },
            buttonClick: (evt) => { 
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah Data';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    self.form = evt.data;
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit Data';
                    this.tbl.api().ajax.url(window.$apiUrl+"/keuangan/jurnal_umum/item/"+self.form.no_jurnal).load();
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    authFetch('/akademik/data_guru/' + evt.data.id, {
                            method: 'DELETE',
                            body: 'id=' + evt.data.id
                        })
                        .then(res => {
                            return res.json();
                        })
                        .then(js => {
                            this.table.api().ajax.reload();
                        });
                }

            },
        });

        await this.loadCoa();
        this.optCoa.data[0] = 'Pilih Coa';
        let coa = this.tbl = createTable(e.tableitem, {
            title: "",
            serverSide: false,
            filter: false,
            ajax: "/pr/siswa_plan_angsuran/skemabayar",
            order: [[ 0, "desc" ]],
            lengthChange: false,
            paging: false,
            info: false, 
            frame: false,
            columns: [
                { "data": "coa_hierarchy", sType:'select', source: this.optCoa.data, change: function(evt, data){
                    let cek = coa.DataTable.row().data()
                    coa.fnUpdate(data.id, evt, 4, false);
                    $('td:nth-child(2) .dt',evt).val(0);
                    $('td:nth-child(3) .dt',evt).val(0);
                }},
                { data: "masuk" },
                { data: "keluar" },
                { data: "action"},
                { data: "coa_id", visible: false},
            ],
        }).fnEditor({
            action: '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Hapus</a></div>'
        })
    }
}
</script>